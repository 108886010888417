import { ChevronRight } from '@pelando/fontawesome/icons';
import {
  IconNameByCategories,
  IdByCategories,
} from '@/presentation/services/categories';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { useEffect, useState } from 'react';
import { userCategoryPreferences } from '@/infra/api/categories/endpoints';
import { CategoryCapsuleIcon, MediaQuery } from '@pelando/components';
import { addOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import useMediaQuery from '@/presentation/hooks/mediaQuery';
import {
  CategoriesContainer,
  CategoriesContainerWrapper,
  CommunitiesStepContainer,
  ContinueButton,
  SearchTextContainer,
  SearchTextContainerGradient,
  SmokyButtonMobile,
  WrapperButton,
} from './style';
import {
  TitleCategoriesOnboardingNew,
  categoriesOnboardingNew,
} from './categoriesOnboardingNew';
import { OnboardingSubtitle, OnboardingTitle } from '../style';
import {
  StepTypes,
  emitOnboardingEndEvent,
  emitOnboardingEvent,
} from '../consts';

import Translation from '../../Translation';

type SelectedCategories = string[];

export type CategoryStepIconType = {
  onClickContinue: () => void;
  categoryName?: string;
  loggedUserId: string;
};

export default function CategoryStepIcon({
  onClickContinue,
  loggedUserId,
}: CategoryStepIconType) {
  const { t } = useTranslation('onboarding');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_MD_DOWN);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [selectedCategories, setSelectedCategories] =
    useState<SelectedCategories>([]);

  const categoryTitle = isMobile ? (
    <>
      <Translation translation={t('onboarding-categories-title-mobile')}>
        Quais <br />
        categorias te
        <br /> interessam?
      </Translation>
    </>
  ) : (
    <>{t('onboarding-categories-title')}</>
  );

  const categorySubtitle = (
    <>
      <Translation translation={t('onboarding-categories-subtitle')} hasTag>
        Escolha no mínimo <strong>três categorias</strong> para deixarmos o
        Pelando com a sua cara.
      </Translation>
    </>
  );
  useEffect(() => {
    addOnboardingStageLocalStorage(StepTypes.CATEGORIES_STEP);
    emitOnboardingEvent(StepTypes.CATEGORIES_STEP, loggedUserId);
  }, [loggedUserId]);

  useEffect(() => {
    const selectedCount = Object.values(selectedCategories).filter(
      (isSelected) => isSelected
    ).length;
    setIsButtonEnabled(selectedCount >= 3);
  }, [selectedCategories]);

  const getCategorySelected = (categoryId: string) =>
    selectedCategories.includes(categoryId);
  const toggleCategory = (categoryId: string) => {
    setSelectedCategories((prevState) => {
      if (getCategorySelected(categoryId)) {
        return prevState.filter((item) => item !== categoryId);
      }

      return [...prevState, categoryId];
    });
  };

  const handleClickToContinue = () => {
    userCategoryPreferences.requestAsPromise({
      categoriesIds: selectedCategories,
    });
    emitOnboardingEndEvent(loggedUserId);
    onClickContinue();
  };

  return (
    <>
      <CommunitiesStepContainer>
        <SearchTextContainer>
          <OnboardingTitle>{categoryTitle}</OnboardingTitle>
          <OnboardingSubtitle>{categorySubtitle}</OnboardingSubtitle>
        </SearchTextContainer>
        <CategoriesContainerWrapper>
          <SearchTextContainerGradient />
          <CategoriesContainer>
            {categoriesOnboardingNew.map((categoryName) => (
              <CategoryCapsuleIcon
                key={TitleCategoriesOnboardingNew[categoryName]}
                icon={IconNameByCategories[categoryName] || ''}
                title={t(TitleCategoriesOnboardingNew[categoryName])}
                active={getCategorySelected(IdByCategories[categoryName])}
                onClick={() => toggleCategory(IdByCategories[categoryName])}
              />
            ))}
          </CategoriesContainer>
        </CategoriesContainerWrapper>
        <WrapperButton>
          <SmokyButtonMobile disabled={!isButtonEnabled} />
          <ContinueButton
            light
            icon={ChevronRight}
            iconPosition="right"
            responsive
            onClick={handleClickToContinue}
            disabled={!isButtonEnabled}
          >
            {t('onboarding-button-continue')}
          </ContinueButton>
        </WrapperButton>
      </CommunitiesStepContainer>
    </>
  );
}
