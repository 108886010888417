import { Category } from '../../../domain/entities/Category';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { CategoriesActionType } from './actionTypes';
import { USER_CATEGORY_PREFERENCES_MUTATION } from './mutations/userCategoryPreferences';
import { MAIN_CATEGORIES_QUERY } from './queries/getMainCategories';
import {
  CategoriesCache,
  MainCategoriesResponse,
} from './types/MainCategories';
import {
  UserCategoryPreferencesResponse,
  UserCategoryPreferencesVariables,
} from './types/postUserCategoryPreferences';

export const getMainCategories = createNewEndpoint<
  MainCategoriesResponse,
  void,
  Category[],
  CategoriesCache
>({
  id: CategoriesActionType.GetMainCategories,
  query: MAIN_CATEGORIES_QUERY,
  method: GraphQlMethods.Get,
  transform: (result) => result.public.mainCategories,
  cacheTransform: ({ data, loading }) => ({
    categories: data,
    loading,
  }),
});

export const userCategoryPreferences = createNewEndpoint<
  UserCategoryPreferencesResponse,
  UserCategoryPreferencesVariables,
  boolean
>({
  id: CategoriesActionType.PostUserCategoryPreferences,
  query: USER_CATEGORY_PREFERENCES_MUTATION,
  method: GraphQlMethods.Post,
  transform: (result) => result.me.userCategoryPreferences,
});
