import styled, { css } from 'styled-components';
import {
  Colors,
  DealVoteButton,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import { OnboardingContainerSteps } from '../style';

export const BACKGROUND_COLOR_VAR = '--background-color';

type VoteContainerProps = {
  hotContainer?: string;
};

export enum TooltipVoteState {
  HOT = 'HOT',
}

export const Container = styled(OnboardingContainerSteps)<VoteContainerProps>`
  &[hotContainer='true'] {
    background-image: url('/assets/onboardingNew/fire.gif');
  }

  justify-content: unset;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 40px 74px;
  }
`;

export const CardImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 280px;
  width: 100%;
  align-items: center;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-top: 0;
    width: 701px;
  }
`;

const cardImageStyle = css<{ voted: boolean }>`
  height: 178px;
  min-width: 343px;
  position: absolute;
  top: 10px;
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(var(${BACKGROUND_COLOR_VAR}), 0.6);
    z-index: 1;
    transition: opacity 0.5s ease;
    opacity: ${({ voted }) => (voted ? 0 : 1)};
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    height: 144px;
    width: 701px;
  }
`;

export const CardImageHot = styled.div<{ voted: boolean }>`
  ${cardImageStyle}
  background-image: url('/assets/onboardingNew/deal-card-mobile-hot.svg');

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-image: url('/assets/onboardingNew/deal-card-hot.svg');
  }
`;

export const CardImageBackground = styled.div`
  height: 160px;
  width: 308px;
  position: absolute;
  top: 60px;
  background-image: url('/assets/onboardingNew/deal-card-mobile-background.svg');
  background-repeat: no-repeat;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(${Theme.colors.Brand}, 0.6);
    z-index: 0;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    background-image: url('/assets/onboardingNew/deal-card-background.svg');
    height: 125px;
    width: 612px;
  }
`;

export const VoteButton = styled(DealVoteButton)`
  position: absolute;
  height: 32px;
  top: 144px;
  left: 36px;
  z-index: 2;
  background-color: rgb(${Colors.White});

  @media ${MediaQuery.SCREEN_MD_UP} {
    top: 104px;
    left: 140px;
  }
`;

export const TooltipContent = styled.p`
  background-color: rgb(${Colors.White});
  color: rgb(${Colors.Red});
  border-radius: 8px;
  position: relative;
  right: 26%;
  top: 192px;
  padding: 10px;
  ${SmallLight}
  z-index: 3;

  &[data-state='${TooltipVoteState.HOT}'] {
    color: rgb(${Colors.Red});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    top: 152px;
    right: 20%;
  }
`;

export const TooltipDetail = styled.div`
  background-color: rgb(${Colors.White});
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  top: 182px;
  transform: rotate(45deg);
  z-index: 3;

  &[data-state='${TooltipVoteState.HOT}'] {
    left: 112px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    top: 142px;

    &[data-state='${TooltipVoteState.HOT}'] {
      left: 224px;
    }
  }
`;
