import { useTranslation } from '@/presentation/hooks/useTranslation';
import { ChevronRight, Forward } from '@pelando/fontawesome/icons';
import { useCallback, useEffect } from 'react';
import { MediaQuery, useMediaQuery } from '@pelando/components';
import { addOnboardingStageLocalStorage } from '@/infra/storage/onboardingManagement';
import {
  VideoContainer,
  VideoContent,
  VideoButton,
  StartButton,
} from './style';
import {
  StepTypes,
  emitOnboardingEvent,
  emitOnboardingSkipVideoEvent,
} from '../consts';

const SHORT_VIDEO_DESKTOP = '/assets/onboardingNew/short-onboarding.mp4';

const SHORT_VIDEO_MOBILE = '/assets/onboardingNew/short-onboarding-mobile.mp4';

const POSTER_DESKTOP = 'assets/onboardingNew/poster-desktop-orange.svg';
const POSTER_MOBILE = 'assets/onboardingNew/poster-mobile-orange.svg';

type VideoStepProps = {
  onClickContinue: () => void;
  onVideoEnded: () => void;
  loggedUserId: string;
};

const VideoStep = ({
  onClickContinue,
  onVideoEnded,
  loggedUserId,
}: VideoStepProps) => {
  const { t } = useTranslation('onboarding');
  const isMobile = useMediaQuery(MediaQuery.SCREEN_MD_DOWN);

  const SHORT_VIDEO = isMobile ? SHORT_VIDEO_MOBILE : SHORT_VIDEO_DESKTOP;
  const POSTER = isMobile ? POSTER_MOBILE : POSTER_DESKTOP;

  useEffect(() => {
    addOnboardingStageLocalStorage(StepTypes.VIDEO_STEP);
    emitOnboardingEvent(StepTypes.VIDEO_STEP, loggedUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVideoEnded = useCallback(() => {
    onVideoEnded();
    onClickContinue();
  }, [onClickContinue, onVideoEnded]);

  const handleSkipVideo = useCallback(() => {
    emitOnboardingSkipVideoEvent(loggedUserId);
    onClickContinue();
  }, [loggedUserId, onClickContinue]);

  return (
    <VideoContainer>
      <VideoContent
        autoPlay
        muted
        onEnded={handleVideoEnded}
        playsInline
        poster={POSTER}
        preload="auto"
      >
        <source src={SHORT_VIDEO} />
      </VideoContent>
      <VideoButton
        onClick={handleSkipVideo}
        icon={Forward}
        iconPosition="right"
      >
        {t('onboarding-button-forward')}
      </VideoButton>

      <StartButton
        light
        icon={ChevronRight}
        iconPosition="right"
        responsive
        onClick={onClickContinue}
      >
        {t('onboarding-button-start')}
      </StartButton>
    </VideoContainer>
  );
};

export default VideoStep;
