import { styled } from 'styled-components';
import { Button, Colors, MediaQuery, Theme } from '@pelando/components';
import { OnboardingContinueButton } from '../style';

type ButtonProps = {
  animate?: boolean;
};

export const VideoContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
`;

export const VideoContent = styled.video`
  display: flex;
  position: relative;
  object-fit: fill;

  @media ${MediaQuery.SCREEN_MD_DOWN} {
    width: 100%;
    min-width: 100vw;
    height: auto;
  }
`;

export const VideoButton = styled(Button)<ButtonProps>`
  display: flex;
  position: fixed;
  top: 16px;
  right: 32px;
  z-index: 100;
  border: 2px solid rgb(${Colors.White}) !important;
  background-color: rgb(${Colors.White}, 0.3) !important;

  opacity: ${({ animate }) => (animate ? 1 : 0)};

  transition: opacity 2s ease-in-out;

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: absolute;
    top: 32px;
    right: 64px;
  }
`;

export const StartButton = styled(OnboardingContinueButton)<ButtonProps>`
  && {
    opacity: ${({ animate }) => (animate ? 1 : 0)};

    transition: opacity 2s ease-in-out;

    i {
      color: rgb(${Theme.colors.Brand});
    }
  }
`;
