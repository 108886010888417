export const MAIN_CATEGORIES_QUERY = `
  query MainCategoriesQuery {
    public {
      mainCategories {
        id
        title
        isGenderRelevant
        communities {
          id
          name
          color
          image
          genderPreference
          slug
        }
      }
    }
  }
`;
